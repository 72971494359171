import React from 'react'
import TopBar from '../Nav/TopBar'
import Hero from '../Natalia/Hero'
function Natalia() {
    return (
        <>
        <TopBar />
        <Hero/>
        </>
    )
}

export default Natalia
