import React from 'react'
import TopBar from '../Nav/TopBar'


import AboutContent from '../About/AboutContent'
function About() {
    return (
        <>
            <TopBar />
            <AboutContent />
        </>
    )
}

export default About
